import React, { useEffect, useState } from "react";
import _ from 'lodash';
import { FaCheck, FaExclamationTriangle } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
const x = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const Body = (props) => {
  const [data, setData] = useState([]);
  const [note, setNote] = useState(null);
  const [mainStatus, setStatus] = useState(null);
 
  const {flag}=props
  useEffect(() => {

    fetch("https://api.staffgenix.com/microservices/status")
      .then((respons) => respons.json())
      .then((json) => {
        const x = json.length > 1 ? json[json.length-1] : null;
            setNote(x);
         json.pop();
        setData(json);
      });
  }, []);



  useEffect(() => {
    if (_.isEmpty(data)) return
    const func = (x) => x.response !== "OK";
    const d = data.some(func);
    if (d) {
      setStatus("notOk");
    }
    if(!d){
      setStatus("OK");
    }
  }, [data]);
  ////
  return (
    <div className="body_countainer" style={{backgroundColor: flag ?"white":"#2d2d2e"}}>
      <div className="icon_wrapper">
        {!mainStatus && (
          <div className="skaltan">
            <Skeleton count={1} circle width={100} height={100} highlightColor={flag ? "white":"#6f6f70"} baseColor={flag ? "#b3b3b5" : "#2d2d2e"} />
          </div>
        )}
        {(mainStatus === "OK" && _.isEmpty(note)) && <FaCheck className="okstatus" />}
        {(mainStatus === "notOk" || !_.isEmpty(note)) && (
          <FaExclamationTriangle className="notokstatus" />
        )}
      </div>
      <div className="heading_wrapper">
        {mainStatus === "OK" && (
          <h1 className="heading" style={{color:flag ? "black":"white"}}>Staff Genix is up and running</h1>
        )}
        {mainStatus === "notOk" && (
          <h1 className="heading" style={{color:flag ? "black":"white"}}>Staff Genix is partially up and running</h1>
        )}
        {!mainStatus && (
          <h1 className="heading">
            <Skeleton className="skaltan_heading" count={1} width={300} highlightColor={ flag ? "white":"#6f6f70"} baseColor={flag ? "#b3b3b5" : "#2d2d2e"}/>
          </h1>
        )}
        <div className="heading_para">
        {
        !_.isEmpty(note) && <p style={{color:flag ? "black":"white"}}>
          {/*<b>Alert : </b>*/}{note.Alert}</p>
        }  
      </div>
     
      </div>
      
    
      <div className="table_wrapper"style={{backgroundColor: flag ? "white":"#2d2d2e"}}>
        <div className="heading_one" >
          <p className="text_heading">
            <b style={{color:flag ? "black":"white"}}>Current Status by Service</b>
          </p>
        </div>
        <div className="heading_two">
          <div className="opt_1">
            <img className="img_icon" src="img/icon1.png" />
            <p className="text_heading" style={{color:flag ? "black":"white"}}>No Issues</p>
          </div>
          <div className="opt_1">
            <img className="img_icon" src="img/icon2.png" />
            <p className="text_heading" style={{color:flag ? "black":"white"}}>Maintenance</p>
          </div>
          <div className="opt_1">
            <img className="img_icon" src="img/icon3.png" />
            <p className="text_heading" style={{color: flag ? "black":"white"}}>Notice</p>
          </div>
          <div className="opt_1">
            <img className="img_icon" src="img/icon4.png" />
            <p className="text_heading" style={{color: flag ? "black":"white"}}>Incident</p>
          </div>
          <div className="opt_1">
            <img className="img_icon" src="img/icon5.png" />
            <p className="text_heading" style={{color: flag ?  "black":"white"}}>Outage</p>
          </div>
        </div>
      </div>
      <div className="wrapperGrid">
        {data.length === 0 &&
          x.map((d) => (
            <div key={d} className="grid-coloumn-skelton ">
              <div className="skelton">
                <Skeleton baseColor={ flag ? "#b3b3b5" : "#2d2d2e"} highlightColor={flag ? "white":"#6f6f70"} />
                <Skeleton width={50} baseColor={ flag ? "#b3b3b5" : "#2d2d2e"} highlightColor={flag ? "white":"#6f6f70"}/>
              </div>
            </div>
          ))}
        {data &&
          data.map((x) => (
            <div key={x.service} className="grid-coloumn">
              <div className="grid_text">
                <b style={{color: flag ? "black":"white"}}>{x.service}</b>
                <div style={{color: flag ? "black":"white"}}>
                  {x.response === "OK" && "No issue"}
                  {x.response !== "OK" && "Outage"}
                </div>
              </div>
              <div>
                {x.response === "OK" && (
                  <img className="img_icon" src="img/icon1.png" />
                )}
                {x.response !== "OK" && (
                  <img className="img_icon" src="img/icon5.png" />
                )}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
export default Body;




