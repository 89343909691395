import React,{useEffect} from "react";
import {BsSun,BsFillMoonFill} from 'react-icons/bs'

const Header = (props) => {
  
  
 
  const {flag,setFlag}=props
  const [a,setA] = React.useState("")



  return (
    <div className="header_countainer" style={{backgroundColor: flag ? "white":"#2d2d2e"}}>
      <div className="header_wrapper">
        <div className="header_logo">
        
            <img
              width=""
              className="logo_image"
              alt="Staff Genix"
              src="img/staff.png"
            />
      
          <div className="statuss">
            <h1 className="logo_status" style={{color:( flag) ? "black":"white"}}>Status</h1>
          </div>
        </div>
        <div>
            {( flag) ? 
            <div style={{display:"flex",alignItems:"center", cursor:'pointer'}}>
              <h3 style={{marginRight:"10px",color:(flag) ? "black":"white"}}>Day</h3>
              <BsSun style={{fontSize:"2.5rem",color:"red"}} onClick={()=>setFlag(!flag)} />
            </div>
            :
            <div style={{display:"flex",alignItems:"center", cursor:'pointer'}}>
              <h3 style={{marginRight:"10px",color:( flag) ? "black":"white"}}>Night</h3>
              <BsFillMoonFill onClick={()=>setFlag(!flag)} style={{fontSize:"2.5rem",color:"dark"}} />
            </div>}
            
        </div>
      </div>
      <div className="headerline" />
    </div>
  );
};

export default Header;
