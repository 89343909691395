import React from 'react'

const Footer = (props) => {

    const year = ()=>{
        var date = new Date();
        date = date.getFullYear()
        return date
      }
      
      const {flag}=props
    return (
        <div className="footer_sec" style={{backgroundColor:flag ? "white":"#2d2d2e"}} >
        <div className="footer_countainer">
            <p className="footer_text" style={{color:flag  ? "black":"white"}} >COPYRIGHT {year()} © Staff Genix . ALL RIGHTS RESERVED.</p>
        </div>
    </div>
    )
}

export default Footer
