import React,{useEffect} from "react";
import Header from "./components/Header/Header";
import Body from "./components/Body/Body";
import Footer from "./components/Footer/Footer";
import "./App.css";
import "react-loading-skeleton/dist/skeleton.css";

function App() {
  const [flag,setFlag]=React.useState(true)
  const hours = new Date().getHours()
  console.log(hours);
  
  const isDayTime = hours > 6 && hours < 17
  
 
  useEffect(()=>{
    if(isDayTime){
      setFlag(true)
    }else{
      setFlag(false)
    }
  },[])

  return (

    <div className={ flag ? "App" : "Dark"} >
      <Header flag={flag} setFlag={setFlag} />
      <Body flag={flag} />
      <Footer flag={flag} />
    </div>
  );
}

export default App;
